



























import mixins from 'vue-typed-mixins'
import Static from '@/builder/sections/section/static/mixins/Static'
export default mixins(Static).extend({
  name: 'PrivacyPolicy',
  created () {
    if (!this.privacyPolicy) {
      this.isLoading = true
      this.fetchPrivacyPolicy().finally(() => {
        this.isLoading = false
      })
    }
  }
})
